/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
//@import './ckeditortheme.scss';


@include mat.core();

$custom-primary : #586e75;
//$custom-secondary: #bfa039;
$custom-secondary: #32bb2e;

*{--global-bg-color: white;}

$primary: mat.define-palette(mat.$blue-palette, 900);
    $accent:  mat.define-palette(mat.$lime-palette,500);
    $warn:    mat.define-palette(mat.$red-palette, 500);

    $theme: mat.define-light-theme($primary, $accent, $warn);

    @include mat.all-component-themes($theme);
.dark-theme{

    

    *{
        color:white;
    }
    :root mat-radio-button{
        color:white;
    }
    :root .mat-checkbox-label{
        color:white;
    }
    :root h1,h2,h3,h4,h5{
        color:white;
    }
    :root .mat-slide-toggle-content{
        color:white;
    }
    :root .mat-input-element{
        color:white !important;
    }

    

    // Define an alternate dark theme.
    $dark-primary: mat.define-palette(mat.$deep-orange-palette);
    $dark-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
    $dark-warn:    mat.define-palette(mat.$deep-orange-palette);
    $dark-theme:   mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

    @include mat.all-component-themes($dark-theme);


    /* Overrides the border radius setting in the theme. */
    --ck-border-radius: 4px !important;

    /* Overrides the default font size in the theme. */
    --ck-font-size-base: 14px !important;

    /* Helper variables to avoid duplication in the colors. */
    --ck-custom-background: hsl(270, 1%, 29%) !important;
    --ck-custom-foreground: hsl(255, 3%, 18%) !important;
    --ck-custom-border: hsl(300, 1%, 22%) !important;
    --ck-custom-white: hsl(0, 0%, 100%) !important;
    --ck-color-base-background: var(--ck-custom-background);
    /* -- Overrides generic colors. ------------------------------------------------------------- */

    --ck-color-base-foreground: var(--ck-custom-background) !important;
    --ck-color-focus-border: hsl(208, 90%, 62%) !important;
    --ck-color-text: hsl(0, 0%, 98%) !important;
    --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2) !important;
    --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1) !important;

    /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

    --ck-color-button-default-background: var(--ck-custom-background) !important;
    --ck-color-button-default-hover-background: hsl(270, 1%, 22%) !important;
    --ck-color-button-default-active-background: hsl(270, 2%, 20%) !important;
    --ck-color-button-default-active-shadow: hsl(270, 2%, 23%) !important;
    --ck-color-button-default-disabled-background: var(--ck-custom-background) !important;

    --ck-color-button-on-background: var(--ck-custom-foreground) !important;
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%) !important;
    --ck-color-button-on-active-background: hsl(255, 4%, 14%) !important;
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%) !important;
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground) !important;

    --ck-color-button-action-background: hsl(168, 76%, 42%) !important;
    --ck-color-button-action-hover-background: hsl(168, 76%, 38%) !important;
    --ck-color-button-action-active-background: hsl(168, 76%, 36%) !important;
    --ck-color-button-action-active-shadow: hsl(168, 75%, 34%) !important;
    --ck-color-button-action-disabled-background: hsl(168, 76%, 42%) !important;
    --ck-color-button-action-text: var(--ck-custom-white) !important;

    --ck-color-button-save: hsl(120, 100%, 46%) !important;
    --ck-color-button-cancel: hsl(15, 100%, 56%) !important;

    /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

    --ck-color-dropdown-panel-background: var(--ck-custom-background) !important;
    --ck-color-dropdown-panel-border: var(--ck-custom-foreground) !important;

    /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

    --ck-color-input-background: var(--ck-custom-foreground) !important;
    --ck-color-input-border: hsl(257, 3%, 43%) !important;
    --ck-color-input-text: hsl(0, 0%, 98%) !important;
    --ck-color-input-disabled-background: hsl(255, 4%, 21%) !important;
    --ck-color-input-disabled-border: hsl(250, 3%, 38%) !important;
    --ck-color-input-disabled-text: hsl(0, 0%, 46%) !important;

    /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

    --ck-color-list-background: var(--ck-custom-background) !important;
    --ck-color-list-button-hover-background: var(--ck-color-base-foreground) !important;
    --ck-color-list-button-on-background: var(--ck-color-base-active) !important;
    --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus) !important;
    --ck-color-list-button-on-text: var(--ck-color-base-background) !important;

    /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

    --ck-color-panel-background: var(--ck-custom-background) !important;
    --ck-color-panel-border: var(--ck-custom-border) !important;

    /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

    --ck-color-toolbar-background: var(--ck-custom-background) !important;
    --ck-color-toolbar-border: var(--ck-custom-border) !important;

    /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

    --ck-color-tooltip-background: hsl(252, 7%, 14%) !important;
    --ck-color-tooltip-text: hsl(0, 0%, 93%) !important;

    /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

    --ck-color-image-caption-background: hsl(0, 0%, 97%) !important;
    --ck-color-image-caption-text: hsl(0, 0%, 20%) !important;

    /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

    --ck-color-widget-blurred-border: hsl(0, 0%, 87%) !important;
    --ck-color-widget-hover-border: hsl(43, 100%, 68%) !important;
    --ck-color-widget-editable-focus-background: var(--ck-custom-white) !important;

    /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

    --ck-color-link-default: hsl(190, 100%, 75%) !important;
}



body {
    font-family: Roboto;
    overflow-x:hidden;
    font-weight: normal;
    margin:0;
    background-color:#eee;
    padding-bottom:1em;
}

h1, h2, h3, h4 {
    font-weight: normal;
}




.mat-toolbar-row,mat-toolbar{
    height:100% !important;
}
.hlt1{
    font-weight: bold;
}

body{
    padding:0;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

/* TOASTS*/

@import '~ngx-toastr/toastr';


/*ck */

.ck-editor__editable_inline {
    min-height: 20em;
    max-height: 40em;
  }



  /*Leaflet */

  .info {
	padding: 6px 8px;
	font: 14px/16px Arial, Helvetica, sans-serif;
	background: white;
	background: rgba(255,255,255,0.8);
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
	border-radius: 5px;
}
.info h4 {
	margin: 0 0 5px;
	color: #777;
}
em{
    font-weight: bold;
}
content-loader{
    margin-left:6em;
}

.selection{
    fill: gray;
}

.required:after { 
    color: red;
    content: "*";
}